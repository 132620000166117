<template>
    <div>
        <v-card>
            <v-card-title>Listado de Articulos</v-card-title>
            <v-card-text>
                <botonera 
                    :botones="botones" 
                    alinear="right"
                    @crear="dialog = !dialog"
                    @cargar="upload = !upload"
                    @eliminar="deleteAll"
                ></botonera>
            </v-card-text>
        </v-card>

        <datatable 
            :cabeceras="articulosListHeaders" 
            :items="articulosListBody" 
            titulo="Listado de Articulos" 
            label="Escriba para buscar Articulos" 
            icono="list" 
            color_icono="azul" 
            :tachar="false"
            :acciones="['editar','eliminar']"
            @editar="editArticulo($event)"
            @eliminar="deleteArticulo($event)">
        </datatable>

        <!-- Modal Nueva Articulo -->
        <v-dialog
            v-model="dialog"
            width="900px"
            @click:outside = "closeModal"
        >
            <v-card>
                <v-card-title class="blue darken-1">{{ title }}</v-card-title>
                <v-card-text>
                    <form>
                        <v-row class="mx-2">
                            <v-col
                                class="align-center justify-space-between"
                                cols="3"
                            >
                                <v-select 
                                    prepend-icon="mdi-factory"
                                    v-model="articulo.cuenta"
                                    :items="cuentas"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Cuenta"
                                    color="blue darken-3"
                                    persistent-hint
                                    return-object
                                >
                                </v-select>
                            </v-col>
                            <v-col
                                class="align-center justify-space-between"
                                cols="3"
                            >
                                <v-select 
                                    prepend-icon="mdi-factory"
                                    v-model="articulo.subcosto"
                                    :items="subcostos"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Subcosto"
                                    color="blue darken-3"
                                    persistent-hint
                                    return-object
                                >
                                </v-select>
                            </v-col>
                            <v-col
                                class="align-center justify-space-between"
                                cols="3"
                            >
                                <v-text-field
                                    prepend-icon="mdi-barcode"
                                    placeholder="Nombre Articulo"
                                    v-model="articulo.nombre"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col
                                class="align-center justify-space-between"
                                cols="3"
                            >
                                <v-text-field
                                    prepend-icon="mdi-barcode"
                                    placeholder="Codigo"
                                    v-model="articulo.codigo"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </form>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        @click="closeModal"
                    >Cerrar</v-btn>
                    <v-btn
                        color="primary"
                        @click="saveArticulo"
                    >Grabar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- / Modal -->

        <!-- Modal upload -->
        <v-dialog
            v-model="upload"
            width="600px"
        >
            <v-card>
                <v-card-title class="green darken-1">
                    Subir listado de articulos desde excel
                </v-card-title>
                <v-card-text>
                    <v-row class="mx-2">
                        <v-col>
                            <v-file-input v-model="file" accept=".xls, .xlsx" label="Archivo Excel"></v-file-input>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        @click="upload = false"
                    >Cancelar</v-btn>
                    <v-btn
                        color="primary"
                        @click="uploadArticulos"
                    >Subir</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- / Modal -->
    
        <v-snackbar
            v-model="snackbar"
            :timeout="5000"
            :color="color"
        >
            {{ validation_message }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
<script>
import {mapState, mapMutations} from 'vuex'
import DataTable from '@/components/base/DataTable.vue';

export default {
    data: () => ({
        title: 'Nuevo Articulo',
        color: 'green',
        snackbar: false,
        errors: [],
        validation_message: '',
        search: null,
        file: null,
        dialog: false,
        upload: false,
        changeState: false,
        articuloIndex: -1,
        cuentas: [],
        subcostos: [],
        articulo: {
            id: 0,
            cuenta: {},
            subcosto: {},
            nombre: '',
            codigo: '',
        },
        articulosListHeaders: [
            {
                text: 'Id',
                align: 'center',
                sortable: true,
                value: 'id',
                class: "blue-grey darken-4 white-text"
            },
            {
                text: 'Agrupación de cuentas',
                align: 'left',
                sortable: true,
                value: 'cuenta.nombre',
                class: "blue-grey darken-4 white-text"
            },
            {
                text: 'Subcosto',
                align: 'left',
                sortable: true,
                value: 'subcosto.nombre',
                class: "blue-grey darken-4 white-text"
            },
            {
                text: 'Descripción de Artículo',
                align: 'left',
                sortable: true,
                value: 'nombre',
                class: "blue-grey darken-4 white-text"
            },
            {
                text: 'Codigo',
                align: 'left',
                sortable: true,
                value: 'codigo',
                class: "blue-grey darken-4 white-text"
            },
            {
                text: 'Acciones',
                align: 'center',
                sortable: false,
                value: 'actions',
                class: "blue-grey darken-4 white-text"
            },
        ],
        articulosListBody: [],
        botones:[
            {boton:'crear',tooltip:'Nuevo Articulo'},
            {boton:'cargar',tooltip:'Carga Masiva'},
            {boton:'eliminar',tooltip:'Borrar Todo'}
        ]
    }),
    mounted() {
        this.loadCuentas();
        this.loadArticulos();
    },
    methods:{
        async loadCuentas(){
            let url = `${this.base_url}cuentas`;
            await this.axios.get(url, this.headers).then((response)=>{
                this.cuentas = response.data;
                this.articulo.cuenta = this.cuentas[0];
                this.loadSubcostos();
            }).catch((error)=>{
                console.log(error);
            });
        },
        async loadSubcostos(){
            let url = `${this.base_url}subcostos/${this.articulo.cuenta.id}`;
            await this.axios.get(url, this.headers).then((response)=>{
                this.subcostos = response.data;
            }).catch((error)=>{
                console.log(error);
            });
        },
        async loadArticulos(){
            let url = `${this.base_url}articulos`;
            await this.axios.get(url, this.headers).then((response)=>{
                this.articulosListBody = response.data;
            }).catch((error)=>{
                console.log(error);
            });
        },
        async saveArticulo(){
            // validaciones
            this.errors = [];
            if(!this.articulo.cuenta){
                this.errors.push("Debe indicar cuenta");
            }
            if(!this.articulo.subcosto){
                this.errors.push("Debe indicar subcosto");
            }
            if(!this.articulo.nombre){
                this.errors.push("Debe indicar nombre del articulo");
            }else if(this.articulo.nombre.length > 50){
                this.errors.push("Tamaño máximo 50 caracteres");
            }
            if(!this.articulo.centro_costos){
                this.errors.push("Debe indicar centro de costos");
            }else if(this.articulo.centro_costos.length > 2){
                this.errors.push("Tamaño máximo 2 caracteres");
            }
            if(this.errors.length > 0){
                this.validation_message = this.errors.join();
                this.showSnackBar(true);
                return;
            }
            let url = `${this.base_url}articulos`;
            let articuloData = {
                'cuentas_id': this.articulo.cuenta.id,
                'subcostos_id': this.articulo.subcosto.id,
                'nombre': this.articulo.nombre,
                'codigo': this.articulo.codigo,
            };
            if(this.articulo.id > 0){
                url = url + "/" + this.articulo.id;
                await this.axios.put(url, articuloData, this.headers).then((response)=>{
                    let index = this.articulosListBody.map(articulo => {
                        return articulo.id;
                    }).indexOf(this.articulo.id);
                    this.articulosListBody.splice(index, 1, response.data);
                    this.showSnackBar(false);
                }).catch((error)=>{
                    this.validation_message = error;
                    this.showSnackBar(true);
                });
            }else{
                await this.axios.post(url, articuloData, this.headers).then((response)=>{
                    this.articulosListBody.push(response.data);
                    this.showSnackBar(false);
                }).catch((error)=>{
                    this.errors = error.response.data;
                    this.validation_message = this.errors.join();
                    this.showSnackBar(true);
                });
            }
            this.cleanForm();
        },
        editArticulo(item) {
            this.articuloIndex = this.articulosListBody.indexOf(item);
            this.articulo = Object.assign({}, item);
            this.dialog = true;
            this.title = "Editar Articulo";
        },
        async deleteArticulo(item) {
            this.$confirm('Desea borrar esta articulo?').then(res => {
                if(res){
                    let url = `${this.base_url}articulos/${item.id}`;
                    this.axios.delete(url, this.headers).then((response)=>{
                        console.log(response);
                        this.loadArticulos();
                        this.showSnackBar(false);
                    }).catch((error)=>{
                        this.validation_message = error;
                        this.showSnackBar(true);
                    });
                }
            });
        },
        showSnackBar(error){
            if(error){
                this.color = 'red';
            }else{
                this.color = 'green';
                this.validation_message = "Ejecutado exitosamente!";
            }
            this.snackbar = true;
        },
        closeModal(){
            this.cleanForm();
            this.dialog = false;
            this.error = null;
            this.title = "Nuevo Articulo";
        },
        cleanForm(){
            this.articulo.id = 0;
            this.articulo.nombre = "";
            this.articulo.empresa = null;
            this.dialog = false;
        },
        async uploadArticulos(){
            this.headers.headers['Content-Type'] = "multipart/form-data";
            let url = `${this.base_url}uploads/articulos`;
            let formData = new FormData();
            formData.append("excel", this.file);
            let response = null; 
            try{
                response = await this.axios.post(url, formData, this.headers);
                this.file = null;
                this.upload = false;
                this.loadArticulos();
                this.showSnackBar(false);
            }catch(error){
                response = error.response;
                let msg = response.data.data;
                this.validation_message = response.status + ': ' + response.data.message + '. '+ msg;
                this.showSnackBar(true);
            }
        },
        async deleteAll(){
            this.$confirm('Desea borrar todos los datos?').then(res => {
                if(res){
                    let url = `${this.base_url}truncar/articulos`;
                    this.axios.get(url, this.headers).then((response)=>{
                        console.log(response);
                        this.loadArticulos();
                        this.showSnackBar(false);
                    }).catch((error)=>{
                        this.validation_message = error;
                        this.showSnackBar(true);
                    });
                }
            });
        },
        async ocultar(item) {
            this.$confirm('Desea ocultar este articulo?').then(res => {
                if(res){
                    let url = `${this.base_url}articulos/ocultar/${item.id}`;
                    this.axios.get(url, this.headers).then((response)=>{
                        console.log(response);
                        this.showSnackBar(false);
                        this.loadArticulos();
                    }).catch((error)=>{
                        this.validation_message = error;
                        this.showSnackBar(true);
                    });
                }
            });
        },
        async mostrar(item) {
            this.$confirm('Desea mostrar este articulo?').then(res => {
            if(res){
                let url = `${this.base_url}articulos/mostrar/${item.id}`;
                this.axios.get(url, this.headers).then((response)=>{
                    console.log(response);
                    this.showSnackBar(false);
                    this.loadArticulos();
                }).catch((error)=>{
                    this.validation_message = error;
                    this.showSnackBar(true);
                });
            }
            });
        },
        ...mapMutations(['actualizarBaseUrl', 'mostrarLoading', 'ocultarLoading']),
    },
    computed:{
        ...mapState(['loading', 'base_url', 'headers']),
    },
    components:{
        'datatable': DataTable
    }
  }
</script>